import Container from "react-bootstrap/Container";
import DynamicProto from "../components/DynamicProto/DynamicProto";
import DynamicApp from "../components/DynamicApp/DynamicApp";
import TceDesc from "../components/ProjectDescription/tcedesc";
import React, { useState } from "react";

export default function TCE() {
  const [pageName] = useState("tce");
  return (
    <>
      <TceDesc />
      <Container>
        <DynamicProto pageName={pageName} />
        <DynamicApp pageName={pageName} />
      </Container>
    </>
  );
}
