import { Container, Row, Col } from "react-bootstrap";

function Diciplines() {
  return (
    <Container>
      <Row>
        <Col>
          <h3 className="orkney-bold">Disciplines</h3>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4 orkney-regular">
        <Col md={6} className="text-center mb-4">
          Throughout the years I have been practising many different disciplines
          and areas of digital experience design and development. Here is an
          overview of my expertise and skills.
        </Col>
      </Row>

      <Row className="justify-content-center mt-4 orkney-medium">
        <Col md={2} className="text-center">
          <p>UI Design</p>
        </Col>
        <Col md={2} className="text-center">
          <p>User Experience Research</p>
        </Col>
      </Row>
      <Row className="justify-content-center orkney-medium">
        <Col md={2} className="text-center">
          <p>UX Design</p>
        </Col>
        <Col md={2} className="text-center">
          <p>Front End Development</p>
        </Col>
      </Row>
      <Row className="justify-content-center orkney-medium">
        <Col md={2} className="text-center">
          <p>Mobile Design</p>
        </Col>
        <Col md={2} className="text-center">
          <p>Back End Development</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Diciplines;
