import "bootstrap/dist/css/bootstrap.min.css";
import DynamicProto from "../components/DynamicProto/DynamicProto";
import DynamicApp from "../components/DynamicApp/DynamicApp";
import GocdDesc from "../components/ProjectDescription/gocddesc";

import React, { useState } from "react";

export default function GoalsOfCare() {
  const [pageName] = useState("gocd");

  return (
    <>
      <GocdDesc />
      <DynamicProto pageName={pageName} />
      <DynamicApp pageName={pageName} />
    </>
  );
}
