import "./App.css";
import "./components/Header/Header.css";
import "./components/Footer/Footer.css";
import "./components/Banner/Banner.css";
import "./components/ProjectCards/Rvh.css";
import "./components/ProjectCards/Tce.css";
import "./components/ProjectCards/Mp.css";
import "./components/AboutMe/AboutMe.css";
import "./components/Headshot/Headshot.css";
import "./components/DynamicProto/DynamicProto.css";
import "./components/DynamicApp/DynamicApp.css";
import "./components/ProjectDescription/gocd.css";
import "./components/TechStack/TechStack.css";
import "./pages/about.css";
import "./pages/home.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/Layout";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import GoalsOfCare from "./pages/gocd";
import TCE from "./pages/tce";
import MattPlayne from "./pages/mp";

function App() {
  return (
    <div>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/gocd" element={<GoalsOfCare />} />
          <Route exact path="/tce" element={<TCE />} />
          <Route exact path="/mp" element={<MattPlayne />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
