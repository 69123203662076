import { Container, Row, Col } from "react-bootstrap";
import Github from "../../assets/icons/github.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
import clickSound from "../../assets/sounds/click-sound.wav";

function Footer() {
  const audio = new Audio(clickSound);
  function playClickSound() {
    audio.play();
  }
  return (
    <Container className="footer text-center">
      {/*Professional Social Medias */}
      <Row className="justify-content-center">
        <Col xs={2} md={1}>
          <a
            onClick={playClickSound}
            href="https://www.linkedin.com/in/matt-playne-2345b7230/"
          >
            <img className="img-fluid" src={LinkedIn} alt="LinkedIn" />
          </a>
        </Col>
        <Col xs={2} md={1}>
          <a onClick={playClickSound} href="https://github.com/mplayne">
            <img className="img-fluid" src={Github} alt="Github" />
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4 mb-1">
          <a
            onClick={playClickSound}
            className="email orkney-light"
            href="mailto:mattplayne@gmail.com"
          >
            mattplayne@gmail.com
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="orkney-light">
            Designed and Developed by Matt Playne. <br /> All Rights Reserved.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
