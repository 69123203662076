import { Card, Container, Row, Col } from "react-bootstrap";
import clickSound from "../../assets/sounds/click-sound.wav";
import TCE from "../../../src/assets/images/TCmock.png";

function Tce() {
  const audio = new Audio(clickSound);
  function playClickSound() {
    audio.play();
  }
  return (
    <Container>
      <Card className=" mx-5 rvh-card">
        <Container>
          <Row className="flex-row-reverse">
            <Col
              xs={12}
              sm={8}
              className="tce-card-img d-flex align-items-center justify-content-center "
            >
              <img
                className=" tce-svg img-fluid"
                src={TCE}
                alt="Your SVG Description"
                style={{ maxHeight: "275px", width: "auto" }}
              />
            </Col>
            <Col xs={12} sm={4}>
              <Card.Body className="m-3">
                <Card.Title className="d-flex justify-content-start mb-4">
                  <h3 className="orkney-medium">Supplier Search - App</h3>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <h6 className="orkney-regular">
                    <strong>UI Design</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">
                  <h6 className="orkney-regular">
                    <strong>UX Design</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Subtitle className="mb-4 text-muted">
                  <h6 className="orkney-regular">
                    <strong>Front End Development</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Text className="d-flex justify-content-start orkney-light">
                  <p>
                    This app allows contractors to find suppliers near them.
                  </p>
                </Card.Text>
                <a
                  onClick={playClickSound}
                  href="./tce"
                  className="read-more orkney-medium"
                >
                  <strong>Read More</strong>
                </a>
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  );
}

export default Tce;
