import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import clickSound from "../../assets/sounds/click-sound.wav";

function Header() {
  const audio = new Audio(clickSound);
  function playClickSound() {
    audio.play();
  }
  return (
    <div classnames="navigation">
      <Navbar collapseOnSelect expand="md">
        <Container>
          <Navbar.Brand onClick={playClickSound} href="/">
            <img
              src={require("../../assets/images/logo-black.png")}
              height="100"
              width="100"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link
                onClick={playClickSound}
                as={NavLink}
                to="/"
                exact
                className="nav-hover"
              >
                Home
              </Nav.Link>
              <div class="mt-2 d-none d-md-block">/</div>
              <Nav.Link
                onClick={playClickSound}
                as={NavLink}
                to="/about"
                exact
                className="nav-hover"
              >
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
