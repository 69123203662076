import { Container, Row, Col } from "react-bootstrap";

function MpDesc() {
  return (
    <Container className="mt-5 mb-4">
      <Row className="d-flex justify-content-between">
        <h1 className="pb-3 proj-title">Matt Playne</h1>
        <h4 className="pb-1 role-desk">Project Managment</h4>
        <h4 className="pb-1 role-desk">UI Design</h4>
        <h4 className="pb-1 role-desk">UX Design</h4>
        <h4 className="pb-3 role-desk">Full Stack Development</h4>
      </Row>
      <Row className="d-flex justify-content-between mt-4">
        <Col xs={12} md={5}>
          <h4 className="pb-1">Overview</h4>
          <p>
            I was accepted for an entrepreneurship co-op with the Henry Bernick
            Center at Georgian College. As students we were required to create a
            business, business plan, marketing strategy and accompany those with
            promotional material. I created a promotional website that allows
            users to explore my music and book live shows. A proof of concept of
            this website was built using Next JS. The version you see below was
            redesigned, and created using React.
          </p>
        </Col>
        <Col xs={12} md={5}>
          <h4>Team</h4>
          <p>Matt Playne</p>

          <h4>Duration</h4>
          <p>8 months</p>
          <h4>Tools</h4>
          <p>
            Adobe XD, Adobe Photoshop, React.js, Node.js, HTML, CSS, Jira,
            Excel, Tally
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default MpDesc;
