import { Container, Row, Col } from "react-bootstrap";
import clickSound from "../../assets/sounds/click-sound.wav";

function Banner() {
  const audio = new Audio(clickSound);
  function playClickSound() {
    audio.play();
  }
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col md={10} className="text-center">
          <h1 className="orkney-regular  banner-col">
            Welcome, <span className="orkney-bold">I'm Matt.</span>
          </h1>
          <h1 className="orkney-regular  banner-col">
            <span className="orkney-bold">I design</span> intuitive and
            effective websites and apps.
          </h1>
          <h1 className="orkney-regular  banner-col">
            Oh, <span className="orkney-bold"> and I develop</span> them too.
          </h1>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center more-row">
        <Col className="text-center" md={2}>
          <a className="tags" href="/about">
            <h5 onClick={playClickSound} className="mt-4 orkney-medium">
              Find Out More
            </h5>
          </a>
        </Col>
        <Col className="text-center" md={2}>
          <a className="tags" href="mailto:mattplayne@gmail.com">
            <h5 onClick={playClickSound} className="mt-4 orkney-medium">
              Get In Touch
            </h5>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Banner;
