import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Banner from "../components/Banner/Banner";
import Rvh from "../components/ProjectCards/Rvh";
import Tce from "../components/ProjectCards/Tce";
import Mp from "../components/ProjectCards/Mp";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

export default function Projects() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container>
      <div className="welcome-banner">
        <Banner />
      </div>
      <div className="projects-header orkney-regular ">
        <h3>Projects</h3>
      </div>
      <div data-aos="fade-left" data-aos-duration="800">
        <Mp />
      </div>
      <div data-aos="fade-left" data-aos-duration="800">
        <Rvh />
      </div>
      <div data-aos="fade-right" data-aos-duration="800">
        <Tce />
      </div>
    </Container>
  );
}
