import Container from "react-bootstrap/Container";
import HeadshotImg from "../../assets/images/Headshot.jpg";

function Headshot() {
  return (
    <Container>
      <img
        className="headshot"
        src={HeadshotImg}
        alt="Matt Playne"
        height="427"
        width="320"
      />
    </Container>
  );
}

export default Headshot;
