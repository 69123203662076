import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function GocdDesc() {
  return (
    <Container className="mt-5 mb-4">
      <Row className="d-flex justify-content-between">
        <h1 className="pb-3 proj-title">Goals of Care</h1>
        <h4 className="pb-1 role-desk">UI Design</h4>
        <h4 className="pb-1 role-desk">UX Design</h4>
        <h4 className="pb-3 role-desk">Full Stack Development</h4>
      </Row>
      <Row className="d-flex justify-content-between mt-4">
        <Col xs={12} md={5}>
          <h4 className="pb-1">Overview</h4>
          <p>
            Royal Victoria Hospital in Barrie partnered with Georgian College to
            design and develop a web application for practitioners and patients.
            This app contains three tools that allow the patient to find their
            expected survival rate, for their current condition or in the case
            of cardiac arrest. The patient can also choose treatment options
            that suite values.
          </p>
        </Col>
        <Col xs={12} md={5}>
          <h4>Team</h4>
          <p>Matt Playne - Research Associate</p>
          <p>Colton Aubrey - Research Associate</p>
          <h4>Duration</h4>
          <p>11 months</p>
          <h4>Tools</h4>
          <p>Adobe XD, Adobe Photoshop, ASP.NET, HTML, CSS, JS</p>
        </Col>
      </Row>
    </Container>
  );
}

export default GocdDesc;
