import Container from "react-bootstrap/Container";

function AboutMe() {
  return (
    <Container>
      <h1 className="mt-2 orkney-bold">The Man Behind The Design</h1>
      <div className="mt-4 about-me orkney-regular">
        <p>
          I’m Matt Playne, I love designing and developing elegant, user
          friendly applications and websites. I graduated from the Interactive
          Media Design - Web at Georgian College and was recruited to work on
          research projects for the college as a Research Associate.
        </p>
        <p>
          As a Research Associate at Georgian College, I'm responsible for
          designing client facing, functional prototypes that would be discussed
          and worked through in our weekly client meetings. Once a design was
          approved, our two man development team translates the design into a
          live web application or website.
        </p>
        <p>
          My specialty is UI design and front-end development. I pride myself on
          thorough research and best practices to back up my design and
          development decisions so that the products I create for you, feel
          intuitive and easy to use.
        </p>
      </div>
    </Container>
  );
}

export default AboutMe;
