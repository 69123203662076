import { Container, Row, Col } from "react-bootstrap";

function TceDesc() {
  return (
    <Container className="mt-5 mb-4">
      <Row className="d-flex justify-content-between">
        <h1 className="pb-3 proj-title">Supplier Search</h1>
        <h4 className="pb-1 role-desk">UX Design / UI Design</h4>
        <h4 className="pb-3 role-desk">Front End Development</h4>
      </Row>
      <Row className="d-flex justify-content-between mt-4">
        <Col xs={12} md={5}>
          <h4 className="pb-1">Overview</h4>
          <p>
            TC Energy Canada partnered with Georgian College to design and
            develop a proof of concept web application for their prime
            contractors. Using data scraped from Google, this application
            filters local suppliers based relevance, on chosen location and
            business size.
          </p>
        </Col>
        <Col xs={12} md={5}>
          <h4>Team</h4>
          <p>Matt Playne - Research Associate</p>
          <p>Colton Aubrey - Research Associate</p>
          <h4>Duration</h4>
          <p>8 months</p>
          <h4>Tools</h4>
          <p>Adobe XD, Adobe Photoshop, ASP.NET, HTML, CSS, JS</p>
        </Col>
      </Row>
    </Container>
  );
}

export default TceDesc;
