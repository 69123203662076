import Xd from "../../assets/icons/xd-icon.svg";
import Ai from "../../assets/icons/ai-icon.svg";
import Ps from "../../assets/icons/ps-icon.svg";
import Css from "../../assets/icons/css-icon.svg";
import Html from "../../assets/icons/html-icon.svg";

import React from "../../assets/icons/react-icon.svg";
import Next from "../../assets/icons/next-icon.svg";
import Node from "../../assets/icons/node-icon.svg";
import CSharp from "../../assets/icons/c-icon.svg";
import Asp from "../../assets/icons/asp-icon.svg";
import Wordpress from "../../assets/icons/wordpress-icon.svg";

import Js from "../../assets/icons/js-icon.svg";

import { Container, Row, Col } from "react-bootstrap";

function TechStack() {
  return (
    <Container>
      <Row>
        <Col>
          <h3 className="orkney-bold">Tech Stack</h3>
        </Col>
      </Row>
      <div className="icon-group">
        <Row className="justify-content-center">
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Xd} alt="Adobe Xd" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Ai} alt="Adobe Ai" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Ps} alt="Adobe Ps" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Css} alt="Adobe Xd" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Html} alt="Adobe Ai" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Js} alt="Adobe Ps" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={React} alt="Adobe Xd" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Next} alt="Adobe Ai" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Node} alt="Adobe Ps" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={CSharp} alt="Adobe Xd" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Asp} alt="Adobe Ai" />
          </Col>
          <Col xs={2} md={1}>
            <img className="img-fluid mb-4" src={Wordpress} alt="Adobe Ps" />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default TechStack;
