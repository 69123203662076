import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

import AboutMe from "../components/AboutMe/AboutMe.js";
import LetsConnect from "../components/LetsConnect/LetsConnect.js";
import Headshot from "../components/Headshot/Headshot.js";
import TechStack from "../components/TechStack/TechStack.js";
import Diciplines from "../components/Diciplines/Diciplines.js";

export default function About() {
  return (
    <Container>
      <Row className="about-me">
        <Col>
          <AboutMe />
        </Col>
        <Col sm={4}>
          <Headshot />
        </Col>
      </Row>
      <Row className="tech-stack">
        <Col>
          <TechStack />
        </Col>
      </Row>
      <Row className="diciplines">
        <Col>
          <Diciplines />
        </Col>
      </Row>
      <Row className="lets-connect">
        <Col>
          <LetsConnect />
        </Col>
      </Row>
    </Container>
  );
}
