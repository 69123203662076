import At from "../../assets/icons/at-icon.svg";

function LetsConnect() {
  return (
    <>
      <h3 className="mt-4 orkney-bold">Get In Touch</h3>
      <div className="mt-4">
        <img
          className=""
          src={At}
          alt="Your SVG Description"
          height="32"
          width="36"
        />
        <a
          className="email p-2 orkney-medium"
          href="mailto:mattplayne@gmail.com"
        >
          mattplayne@gmail.com
        </a>
      </div>
    </>
  );
}

export default LetsConnect;
