import Container from "react-bootstrap/Container";
import DynamicProto from "../components/DynamicProto/DynamicProto";
import DynamicApp from "../components/DynamicApp/DynamicApp";
import MpDesc from "../components/ProjectDescription/mpdesc";
import React, { useState } from "react";

export default function MattPlayne() {
  const [pageName] = useState("mp");
  return (
    <Container>
      <MpDesc />
      <DynamicProto pageName={pageName} />
      <DynamicApp pageName={pageName} />
    </Container>
  );
}
