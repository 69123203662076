import { Card, Container, Row, Col } from "react-bootstrap";
import clickSound from "../../assets/sounds/click-sound.wav";
import MP from "../../../src/assets/images/Mockity.png";

function Mp() {
  const audio = new Audio(clickSound);
  function playClickSound() {
    audio.play();
  }
  return (
    <Container>
      <Card className="mx-5 rvh-card">
        <Container>
          <Row>
            <Col
              xs={12}
              sm={8}
              className="mp-card-img d-flex align-items-center justify-content-center "
            >
              <img
                className="mt-4 gocd-svg"
                src={MP}
                alt="Matt Playne Website "
                style={{ maxHeight: "275px", width: "auto" }}
              />
            </Col>

            <Col xs={12} sm={4}>
              <Card.Body className="m-3">
                <Card.Title className="d-flex justify-content-start mb-4">
                  <h3 className="orkney-medium">
                    Matt Playne Official Music - Website
                  </h3>
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted">
                  <h6 className="orkney-regular">
                    <strong>UI Design</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">
                  <h6 className="orkney-regular">
                    <strong>UX Design</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">
                  <h6 className="orkney-regular">
                    <strong>Full Stack Development</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Subtitle className="mb-4 text-muted">
                  <h6 className="orkney-regular">
                    <strong>Project Managment</strong>
                  </h6>
                </Card.Subtitle>
                <Card.Text className="d-flex justify-content-start orkney-light">
                  <p>
                    A personal website that allows users to explore my music and
                    book live shows.
                  </p>
                </Card.Text>
                <Row>
                  <Col className="d-flex justify-content-between">
                    <a
                      onClick={playClickSound}
                      href="./mp"
                      className="read-more orkney-medium"
                    >
                      <strong>Read More</strong>
                    </a>
                  </Col>
                  <Col className="d-flex justify-content-between">
                    <a
                      onClick={playClickSound}
                      href="https://www.mattplayne.ca/"
                      className="read-more orkney-medium"
                    >
                      <strong>Explore The Site</strong>
                    </a>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  );
}

export default Mp;
