import Container from "react-bootstrap/Container";
import GOCD from "../../../src/assets/images/goalsmock.png";
import TCE from "../../../src/assets/images/TCmock.png";
import MP from "../../../src/assets/images/Mockity.png";
import Button from "react-bootstrap/Button";
import clickSound from "../../assets/sounds/click-sound.wav";

function DynamicApp({ pageName }) {
  const audio = new Audio(clickSound);
  function playClickSound() {
    audio.play();
  }
  let protoImage;
  switch (pageName) {
    case "gocd":
      protoImage = (
        <div>
          <img src={GOCD} alt="Goals Prototype" height="320" width="auto" />
          <div>
            <p className="text-center mt-5">
              Due to a NDA, this app is only available for medical
              professionals.
              <br />
              If you would like to see the app in action, I can give you a
              personal tour.
            </p>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button>
              <a
                onClick={playClickSound}
                className="email orkney-light text-light"
                href="mailto:mattplayne@gmail.com"
              >
                Request Tour
              </a>
            </Button>
          </div>
        </div>
      );
      break;
    case "tce":
      protoImage = (
        <div>
          <img src={TCE} alt="TCE Mockup" height="320" width="auto" />

          <p className="text-center mt-5">
            Due to a NDA, this app is not available for demonstration.
          </p>
        </div>
      );
      break;
    case "mp":
      protoImage = (
        <a href="https://mattplayne.ca/">
          <img src={MP} alt="MP Prototype" height="320" width="auto" />
          <Button className="d-block m-auto p-4" variant="link">
            Explore The Website
          </Button>
        </a>
      );
      break;
    default:
      protoImage = null;
      break;
  }

  return (
    <Container>
      <h1>Finished Product</h1>
      <div className="d-flex justify-content-center m-4">{protoImage}</div>
    </Container>
  );
}

export default DynamicApp;
