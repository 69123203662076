import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import GoalsProto from "../../assets/images/gp1.png";
import TceProto from "../../assets/images/tce-proto.svg";
import MpProto from "../../assets/images/mp-proto.svg";

function DynamicProto({ pageName }) {
  let protoImage;
  switch (pageName) {
    case "gocd":
      protoImage = (
        <a href="https://xd.adobe.com/view/e77a1980-9fe0-486d-93fb-9d0ec908cef0-b229/">
          <img
            src={GoalsProto}
            alt="Goals Prototype"
            height="450"
            width="900"
            className="img-fluid"
          />
          <Button className="d-block m-auto p-4" variant="link">
            Explore The Prototype
          </Button>
        </a>
      );
      break;
    case "tce":
      protoImage = (
        <a href="https://xd.adobe.com/view/c450a12b-10a3-4a07-86bb-165de83e05a1-9103/">
          <img
            src={TceProto}
            alt="TCE Prototype"
            height="450"
            width="900"
            className="img-fluid"
          />
          <Button className="d-block m-auto p-4" variant="link">
            Explore The Prototype
          </Button>
        </a>
      );
      break;
    case "mp":
      protoImage = (
        <a href="https://xd.adobe.com/view/6a2edd28-6274-4fde-b995-601be463f62d-3f7d/">
          <img
            src={MpProto}
            alt="MP Prototype"
            height="450"
            width="900"
            className="img-fluid"
          />
          <Button className="d-block m-auto p-4" variant="link">
            Explore The Prototype
          </Button>
        </a>
      );
      break;
    default:
      protoImage = null;
      break;
  }

  return (
    <Container className="proto-app-container">
      <h1>Prototype</h1>
      <div className="d-flex justify-content-center m-4">{protoImage}</div>
    </Container>
  );
}

export default DynamicProto;
